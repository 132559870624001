<route>
{
  "meta": {
    "auth": "salesWagesExportExcel"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <el-form :model="form" label-width="160px">
        <el-form-item label="核销时间">
          <el-date-picker
            class="searchInput"
            v-model="time"
            value-format="timestamp"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="新旧客判断日">
          <el-input-number
            v-model="form.customerQueryMonth"
            controls-position="right"
            :min="0"
            :step="1"
            :max="31"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="新客提成比例">
          <el-input-number
            v-model="form.newCustomerRatio"
            controls-position="right"
            :min="0"
            :step="1"
            :max="100"
          ></el-input-number>
          <div class="endLabel">%</div>
        </el-form-item>

        <el-form-item label="旧客提成比例">
          <el-input-number
            v-model="form.oldCustomerRatio"
            controls-position="right"
            :min="0"
            :step="1"
            :max="100"
          ></el-input-number>
          <div class="endLabel">%</div>
        </el-form-item>
        <bm-areaSelect v-model="form.areaId"></bm-areaSelect>
        <el-form-item>
          <el-button @click="download">下载</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    time: [],
    form: {
      customerQueryMonth: '21',
      endTime: '',
      startTime: '',
      newCustomerRatio: '25',
      oldCustomerRatio: '15',
      areaId: ''
    }
  }),
  methods: {
    download() {
      if (this.time.length === 0) {
        this.$message.error('需要先选择核销时间')
        return
      } else {
        this.form.startTime = this.time[0]
        this.form.endTime = this.time[1]
      }
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .get('/boom-center-statistics-service/sysAdmin/exportExl/salesWagesExportExcel', {
          params: this.form,
          headers: {
            ['Bm-Area-Id']: this.form.areaId
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.el-date-editor,
.el-input-number {
  width: 360px;
}
.endLabel {
  display: inline-block;
  margin-left: 10px;
}
</style>
